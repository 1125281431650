<template>
	<modal class="NoxModalKYCTests" name="NoxModalKYCTests" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_open_loading" v-if="noxIsOpenLoading"><div class="nox_loading"></div></div>
			<div class="nox_modal_open" v-else>
				<div class="nox_modal_head">
					<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
					<div class="nox_modal_title" v-html="$store.getters.getLanguageText('5.8', 0)"></div>
				</div>
				<div class="nox_modal_body">
					<div class="nox_modal_info">
						<div v-if="noxKYCTestStatus == 1 && Object.keys(noxKYCQuestion).length">
							<p v-html="($store.state.noxLanguage == 'en' ? noxKYCQuestion.question_en : noxKYCQuestion.question_ru)"></p>
							<p class="nox_p_radios" v-if="noxKYCAnswers.length">
								<label class="nox_radio" :for="'nox_radio_kyc_answer_' + index" v-for="(value, index) in noxKYCAnswers" :key="index">
									<input type="radio" :id="'nox_radio_kyc_answer_' + index" name="nox_radio_kyc_answer" :value="value" v-model.number="noxKYCAnswer">
									<span class="nox_radio_mark"></span>
									<span class="nox_radio_text" v-html="($store.state.noxLanguage == 'en' ? value.answer_en : value.answer_ru)"></span>
								</label>
							</p>
							<div v-html="noxAlertKYCAnswer"></div>
						</div>
						<div class="center" v-else-if="noxKYCTestStatus == 2" v-html="$store.getters.getLanguageText('5.8', 2)"></div>
						<div v-else v-html="$store.getters.getLanguageText('5.8', 1)"></div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="button" class="nox_button common" @click="close" v-if="noxKYCTestStatus == 0" v-html="$store.getters.getLanguageText('1.1', 123)"></button>
						<button type="button" class="nox_button common" @click="close" v-else v-html="$store.getters.getLanguageText('1.1', 3)"></button>
						<button type="button" class="nox_button common green" @click="noxKYCTestStatus = 1" v-if="noxKYCTestStatus == 0" v-html="$store.getters.getLanguageText('1.1', 124)"></button>
						<button type="button" class="nox_button common green" @click="axios('addKYCTest')" v-else-if="noxKYCTestStatus == 1" v-html="$store.getters.getLanguageText('1.1', 64)"></button>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertKYCAnswer: '',
			noxKYCQuestion: {},
			noxKYCQuestionsCount: 0,
			noxKYCQuestionsPassed: 0,
			noxKYCAnswers: [],
			noxKYCAnswer: {},
			noxKYCTestStatus: 0,
			noxIsOpenLoading: false,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function() {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxKYCQuestion = {};
				this.noxKYCQuestionsCount = 0;
				this.noxKYCQuestionsPassed = 0;
				this.noxKYCAnswers = [];
				this.noxKYCAnswer = {};
				this.noxKYCTestStatus = 0;
				this.noxIsOpenLoading = false;
				this.noxIsLoading = false;
				this.noxIsError = false;

				this.axios('getKYCTest');
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertKYCAnswer = '';
			},
			getError: function(i, text) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.16', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.16', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.16', 2); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.16', 3); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.16', 4); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.16', 5); }
				else if (i == 7) { this.noxTemp = text; }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText((i == 7 ? '1.3.16' : '1.3'), (i == 7 ? 6 : 0)) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (type == 'getKYCTest') {
						_this.noxIsOpenLoading = true;
						config.url = '/v2/account/kyc/tests';
					}
					else if (type == 'addKYCTest') {
						if (_this.noxKYCAnswer.id == undefined) {
							_this.noxAlertKYCAnswer = _this.getError(3);
						}
						else if (!_this.$store.state.noxRegex_d.test(_this.noxKYCAnswer.id) || _this.noxKYCAnswer.id < 1) {
							_this.noxAlertKYCAnswer = _this.getError(4);
						}
						else if (!_this.noxKYCAnswer.is_correct) {
							_this.noxAlert = _this.getError(7, _this.noxKYCQuestion['answer_' + _this.$store.state.noxLanguage]);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/account/kyc/tests';
							config.data = { qid: _this.noxKYCQuestion.id, aqid: _this.noxKYCAnswer.id };
							config.method = 'post';
						}
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (['getKYCTest', 'addKYCTest'].includes(type)) {
									if (data.data.kyc_question !== undefined) {
										_this.noxKYCQuestion = data.data.kyc_question;
									}
									if (data.data.kyc_questions_count !== undefined) {
										_this.noxKYCQuestionsCount = Number(data.data.kyc_questions_count);
									}
									if (data.data.kyc_questions_passed !== undefined) {
										_this.noxKYCQuestionsPassed = Number(data.data.kyc_questions_passed);
									}
									if (data.data.kyc_answers !== undefined) {
										_this.noxKYCAnswers = data.data.kyc_answers;
										_this.noxKYCAnswer = {};
									}
									_this.noxKYCTestStatus = (_this.noxKYCQuestionsPassed ? (_this.noxKYCQuestionsPassed == _this.noxKYCQuestionsCount ? 2 : 1) : 0);
									_this.$store.state.noxAccountData.is_kyc = (_this.noxKYCTestStatus == 2 ? 1 : 0);
									_this.$store.state.noxAccountData.kyc_at = ((type == 'addKYCTest' && _this.noxKYCTestStatus == 2) ? (Number(_this.$store.state.noxSystemSettings.time) + (Number(_this.$store.state.noxSystemSettings.kyc_lifetime) * 24 * 60 * 60)) : _this.$store.state.noxAccountData.kyc_at);
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (type == 'addKYCTest') {
										if      (data.response.data.error == 'QID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'QID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'AQID_IS_EMPTY') { _this.noxAlertKYCAnswer = _this.getError(3); }
										else if (data.response.data.error == 'AQID_NOT_VALID') { _this.noxAlertKYCAnswer = _this.getError(4); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (type == 'addKYCTest') {
										if      (data.response.data.error == 'QID_NOT_FOUND') { _this.noxAlert = _this.getError(5); }
										else if (data.response.data.error == 'AQID_NOT_FOUND') { _this.noxAlert = _this.getError(6); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsOpenLoading = false;
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalKYCTests');
			}
		}
	}
</script>
